<template>
  <div>
    <v-container>
      <Viewallpds7 />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    Viewallpds7: () => import("@/components/viewallpds7"),
  },
};
</script>